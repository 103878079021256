






































import Vue from "vue";
import { Component } from "vue-property-decorator";
import LoomIcon from "./components/LoomIcon.vue";
import Sidebar from "./components/Sidebar.vue";
import AccountChip from "./components/AccountChip.vue";
import NetworkChip from "./components/NetworkChip.vue";
import AppFooter from "./components/AppFooter.vue";
import ethereumStore from "./store/ethereum";

@Component({
  components: {
    LoomIcon,
    Sidebar,
    AppFooter,
    AccountChip,
    NetworkChip
  }
})
export default class App extends Vue {
  showSidebar = !this.$vuetify.breakpoint.mobile;

  get isWalletSelected() {
    return ethereumStore.wallet.address !== null;
  }

  get isAccountChipVisible() {
    return this.$route.path !== "/login";
  }
}

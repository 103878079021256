





































import { Component, Vue, Prop } from "vue-property-decorator";
import ethereumStore, { WalletKind } from "@/store/ethereum";

/**
 * This component renders the current Ethereum wallet address when known, otherwise it renders
 * a link to the wallet selection page.
 */
@Component
export default class AccountChip extends Vue {
  // HACK to make the enum accessible in the Vue template
  WalletKind = WalletKind;

  @Prop({ default: false })
  readonly compact!: boolean;

  get address() {
    let address = ethereumStore.wallet.address;
    if (this.compact && address !== null) {
      address = address.substr(0, 6) + "..." + address.substr(address.length - 4, 4);
    }
    return address;
  }

  get redirectPath() {
    return this.$route.path === "/login"
      ? this.$route.path
      : `/login?redirect=${this.$route.path}`;
  }

  get walletKind() {
    return ethereumStore.wallet.kind;
  }
}

import Vue from "vue";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

const DATE_TIME_FORMAT_EXT = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
});

const DATE_TIME_FORMAT = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit"
});

const DATE_FORMAT = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "short",
  day: "numeric"
});

/**
 * Converts a UNIX timestamp (seconds since epoch) to a human friendly date + time string.
 * @param timeStamp Timestamp to convert.
 * @param showSeconds Optional parameter that specifies whether or not seconds will be shown in the
 *                    returned string (defaults to `false`).
 */
function formatDateTime(timeStamp: number | string, showSeconds?: boolean): string {
  if (showSeconds) {
    return DATE_TIME_FORMAT_EXT.format(new Date(parseInt("" + timeStamp, 10) * 1000));
  }
  return DATE_TIME_FORMAT.format(new Date(parseInt("" + timeStamp, 10) * 1000));
}

// Converts a UNIX timestamp (seconds since epoch) to a human friendly date string.
function formatDate(timeStamp: number | string): string {
  return DATE_FORMAT.format(new Date(parseInt("" + timeStamp, 10) * 1000));
}

/**
 * Converts a BigNumber representing a token amount with 18 decimals to human readable string.
 * @param num Amount as a BigNumber.
 * @param dp Optional parameter that specifies the number of decimal places to show, defaults to 3.
 */
function formatBigNumber(num: ethers.BigNumberish, dp?: number): string {
  return new BigNumber(num.toString())
    .div(10 ** 18)
    .toFormat(dp === undefined ? 3 : dp, BigNumber.ROUND_DOWN);
}

export default function registerVueFilters() {
  Vue.filter("utime2datetime", formatDateTime);
  Vue.filter("utime2date", formatDate);
  Vue.filter("bn2tokens", formatBigNumber);
}

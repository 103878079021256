







































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ethereumStore from "@/store/ethereum";

const stakingDashboard = "https://dashboard.dappchains.com";

@Component
export default class Sidebar extends Vue {
  internalLinks = [
    {
      label: "Token Swap",
      to: "/token-swap",
      icon: "mdi-swap-horizontal-bold",
      color: "background"
    },
    {
      label: "FAQ",
      to: "/faq",
      icon: "mdi-help-box",
      color: "background"
    },
    {
      label: "Contact Support",
      to: "/feedback",
      icon: "mdi-face-agent",
      color: "background"
    }
  ];

  externalLinks = [
    {
      label: "Staking",
      href: `${stakingDashboard}/account`,
      target: "_blank",
      icon: "mdi-chart-box",
      color: "background"
    },
    {
      label: "Staking Guide",
      href: `${stakingDashboard}/staking-guide`,
      target: "_blank",
      icon: "mdi-book-open",
      color: "background"
    }
  ];

  @Prop({ default: false })
  readonly value!: boolean;

  get showDrawer() {
    return this.value;
  }

  set showDrawer(show: boolean) {
    this.$emit("input", show);
  }

  get isLoggedIn() {
    return ethereumStore.wallet.address !== null;
  }

  logout() {
    // the token swap page requires a wallet to be connected in order to show anything useful
    // so redirect the user to wallet selection page
    if (this.$route.name === "TokenSwap") {
      this.$router.replace("/login");
    }
    ethereumStore.selectWallet(null).catch(err => console.error(err));
  }
}

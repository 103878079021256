

































































import { Component, Vue } from "vue-property-decorator";
import { ethers } from "ethers";
import SwapStepperDlg from "@/components/SwapStepperDlg.vue";
import ethereumStore from "@/store/ethereum";

@Component({
  components: {
    SwapStepperDlg
  }
})
export default class TokenSwap extends Vue {
  refreshTimer = 0;
  showSwapDlg = false;

  created() {
    // TODO: move this to the store, here we should just request for the balances to be updated
    // Recursively calling setTimeout ensures each refresh completes before the next one,
    // which can't be guaranteed when using setInterval.
    const scheduleRefresh = () => {
      this.refreshTimer = window.setTimeout(() => {
        ethereumStore
          .refreshLoomBalances()
          .then(() => scheduleRefresh())
          .catch(err => console.error(err));
      }, 15000);
    };
    ethereumStore.refreshLoomBalances().then(() => scheduleRefresh());
  }

  destroyed() {
    window.clearTimeout(this.refreshTimer);
    this.refreshTimer = 0;
  }

  get oldLoomBalance(): ethers.BigNumber {
    return ethereumStore.wallet.oldLoomToken.balance;
  }

  get newLoomBalance(): ethers.BigNumber {
    return ethereumStore.wallet.newLoomToken.balance;
  }

  get swapEnabled(): boolean {
    if (ethereumStore.oldLoomContract === null) {
      console.error(`swapEnabled(): ethereumStore.oldLoomContract === null`);
    }
    if (ethereumStore.tokenSwapContractAddress === null) {
      console.error(`swapEnabled(): ethereumStore.tokenSwapContractAddress === null`);
    }
    if (ethereumStore.wallet.provider === null) {
      console.error(`swapEnabled(): ethereumStore.wallet.provider === null`);
    }
    return (
      ethereumStore.oldLoomContract !== null &&
      ethereumStore.tokenSwapContractAddress !== null &&
      ethereumStore.wallet.provider !== null &&
      !this.oldLoomBalance.isZero()
    );
  }

  get isBalanceRefreshing(): boolean {
    return ethereumStore.wallet.isRefreshing;
  }

  get amountTitleClasses() {
    return {
      "text-subtitle-1": !this.$vuetify.breakpoint.mobile
    };
  }

  get amountClasses() {
    return {
      "text-h5": !this.$vuetify.breakpoint.mobile,
      "text-subtitle-1": this.$vuetify.breakpoint.mobile,
      monospace: true
    };
  }
}

import Vue from "vue";
import Vuex from "vuex";
import { config } from "vuex-module-decorators";
import { IEthereumState } from "./ethereum";
import { IStakingState } from "./staking";

Vue.use(Vuex);

export interface IRootState {
  ethereum: IEthereumState;
  staking: IStakingState;
}

// set rawError to true by default on all @Action decorators as per
// https://github.com/championswimmer/vuex-module-decorators/pull/252
config.rawError = true;

// the store modules will be dynamically registered later
export default new Vuex.Store<IRootState>({});

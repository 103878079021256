














































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { ethers } from "ethers";
import ethereumStore from "@/store/ethereum";
import tokenSwapABI from "../contract-abi/TokenSwap";

enum Status {
  Default = 0,
  CheckAllowance,
  SendApproveTx,
  ApproveTxSent,
  ApproveTxMined,
  SendSwapTx,
  SwapTxSent,
  SwapTxMined
}

@Component
export default class SwapStepperDlg extends Vue {
  // HACK to make the enum accessible in the Vue template
  Status = Status;

  currentStep = 1;
  status: Status = Status.Default;
  errorMsg: string | null = null;
  amount: ethers.BigNumber = ethers.constants.Zero;
  swapTxURI: string | null = null;

  @Prop({ default: false })
  readonly value!: boolean;

  get showDlg() {
    return this.value;
  }

  set showDlg(show: boolean) {
    this.$emit("input", show);
  }

  cancelDlg() {
    this.showDlg = false;
  }

  get oldLoomBalance(): ethers.BigNumber {
    return ethereumStore.wallet.oldLoomToken.balance;
  }

  get preApproveStepRules() {
    return [() => this.currentStep !== 1 || this.errorMsg === null];
  }

  get approveStepRules() {
    return [() => this.currentStep !== 2 || this.errorMsg === null];
  }

  get swapStepRules() {
    return [() => this.currentStep !== 3 || this.errorMsg === null];
  }

  async checkAllowance() {
    if (!ethereumStore.wallet.address || !ethereumStore.oldLoomContract) {
      return;
    }

    const account = ethereumStore.wallet.address;
    const oldLoomContract = ethereumStore.oldLoomContract;
    this.amount = await oldLoomContract.balanceOf(account);
    // check existing allowance first, maybe don't need to approve
    this.status = Status.CheckAllowance;
    const allowance: ethers.BigNumber = await oldLoomContract.allowance(
      account,
      ethereumStore.tokenSwapContractAddress!
    );

    if (this.amount.gt(allowance)) {
      await this.approve();
      return;
    }

    await this.swap();
  }

  async approve() {
    if (
      !ethereumStore.wallet.address ||
      !ethereumStore.wallet.provider ||
      !ethereumStore.oldLoomContract
    ) {
      return;
    }

    this.errorMsg = null;
    this.currentStep = 2;

    const account = ethereumStore.wallet.address;
    const signer = ethereumStore.wallet.provider.getSigner(account);
    const oldLoomContract = ethereumStore.oldLoomContract.connect(signer);

    let txResponse: ethers.ContractTransaction;
    try {
      this.status = Status.SendApproveTx;
      txResponse = await oldLoomContract.approve(
        ethereumStore.tokenSwapContractAddress!,
        this.amount
      );
      this.status = Status.ApproveTxSent;
    } catch (err) {
      this.errorMsg = err.message;
      throw err;
    }

    try {
      await txResponse.wait();
      this.status = Status.ApproveTxMined;
    } catch (err) {
      this.errorMsg = err.message;
      throw err;
    }

    await this.swap();
  }

  async swap() {
    if (!ethereumStore.wallet.address || !ethereumStore.wallet.provider) {
      return;
    }

    this.errorMsg = null;
    this.swapTxURI = null;
    this.currentStep = 3;

    const account = ethereumStore.wallet.address;
    const signer = ethereumStore.wallet.provider.getSigner(account);
    const swapContract = new ethers.Contract(
      ethereumStore.tokenSwapContractAddress!,
      tokenSwapABI,
      signer
    );
    const network = await ethereumStore.wallet.provider.getNetwork();

    let txResponse: ethers.ContractTransaction;
    try {
      this.status = Status.SendSwapTx;
      txResponse = await swapContract.swap();
      this.status = Status.SwapTxSent;
    } catch (err) {
      this.errorMsg = err.message;
      throw err;
    }

    let txReceipt: ethers.ContractReceipt;
    try {
      txReceipt = await txResponse.wait();
      this.status = Status.SwapTxMined;
    } catch (err) {
      this.errorMsg = err.message;
      throw err;
    }

    if (network.chainId === 1) {
      this.swapTxURI = "https://etherscan.io/tx/" + txReceipt.transactionHash;
    } else if (network.chainId === 4) {
      this.swapTxURI = "https://rinkeby.etherscan.io/tx/" + txReceipt.transactionHash;
    } else {
      this.swapTxURI = null;
    }

    console.log(`Transaction hash ${txReceipt.transactionHash}`);
    await ethereumStore.refreshLoomBalances();
  }

  get cardContentClasses() {
    return {
      "main-card-content": true,
      "text--primary": true,
      "text-body-1": !this.$vuetify.breakpoint.mobile,
      "text-body-2": this.$vuetify.breakpoint.mobile
    };
  }
}

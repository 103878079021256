
































import { Component, Vue } from "vue-property-decorator";
import ethereumStore, { WalletKind } from "../store/ethereum";

@Component
export default class WalletSelection extends Vue {
  // HACK to make the enum accessible in the Vue template
  WalletKind = WalletKind;

  async selectWallet(wallet: WalletKind) {
    await ethereumStore.selectWallet(wallet);
    // redirect back to whichever page the user got redirected from initially (if any)
    const redirect = (this.$route.query as any).redirect;
    if (redirect !== undefined) {
      this.$router.push({ path: redirect });
    } else {
      this.$router.push({ path: "/" });
    }
  }
}

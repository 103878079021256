import { VuexModule, Module, getModule, Action, Mutation } from "vuex-module-decorators";
import { ethers, providers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import store from ".";
import erc20abi from "../contract-abi/IERC20";
import stakingContractABI from "../contract-abi/StakingPool";

export enum WalletKind {
  MetaMask = 0,
  WalletConnect
}

interface IPersistedState {
  walletKind: WalletKind | null;
  networkOverride: number | null;
}

const LOCAL_STORAGE_KEY = "state";

interface IWallet {
  kind: WalletKind | null;
  network: number | null; // Ethereum network the wallet is connected to
  address: string | null;
  provider: providers.Web3Provider | null;
  wcProvider: any; // WalletConnect provider
  signer: ethers.Signer | null;
  oldLoomToken: {
    address: string | null;
    balance: ethers.BigNumber;
  };
  newLoomToken: {
    address: string | null;
    balance: ethers.BigNumber;
  };
  isRefreshing: boolean;
}

export interface IEthereumState {
  wallet: IWallet;
  readOnlyNetworkOverride: number | null;
  readOnlyProvider: providers.Provider | null;
  oldLoomContract: ethers.Contract | null;
  newLoomContract: ethers.Contract | null;
  stakingContract: ethers.Contract | null;
  latestBlockTime: number;
}

type ContractDirectory = {
  oldToken: string;
  newToken?: string;
  staking?: string;
  swap?: string;
};
const CONTRACT_ADDRESS: { [network: number]: ContractDirectory } = {
  1: {
    // mainnet
    oldToken: "0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0",
    newToken: "0x42476F744292107e34519F9c357927074Ea3F75D",
    staking: undefined,
    swap: "0x7711863244783348Ae78c2BDebb9802b297DCE56"
  },
  4: {
    // rinkeby
    oldToken: "0x493640B5BEFB0962CE0932653987C41aA3608bd0",
    newToken: "0xF0265F31D8Ec34Eb32a2928288a5F12a284eA822",
    staking: "0x1EA6707dE37711F795d33B394cB2Bcd85C77788D",
    swap: "0x973B37eA69Ab2D5FBe6C9556e98b4a87a24a7A02"
  }
};

@Module({ name: "ethereum", store, dynamic: true, namespaced: true })
class EthereumStore extends VuexModule implements IEthereumState {
  initialized = false; // will be true after the store is initialized the first time
  wallet: IWallet = {
    kind: null,
    network: null,
    address: null,
    provider: null,
    wcProvider: null,
    signer: null,
    oldLoomToken: {
      address: null,
      balance: ethers.BigNumber.from(0)
    },
    newLoomToken: {
      address: null,
      balance: ethers.BigNumber.from(0)
    },
    isRefreshing: false
  };

  readOnlyNetworkOverride: number | null = null;
  readOnlyProvider: providers.Provider | null = null;
  oldLoomContract: ethers.Contract | null = null;
  newLoomContract: ethers.Contract | null = null;
  stakingContract: ethers.Contract | null = null;

  latestBlockTime = 0;
  latestBlockRequesterCount = 0;
  latestBlockTimer = 0;

  get tokenSwapContractAddress(): string | null {
    return CONTRACT_ADDRESS[this.networkId].swap || null;
  }

  /**
   * @return The identifier of the Ethereum network that hosts the smart contracts the dashboard
   *         interacts with.
   */
  get networkId(): number {
    let network = 1; // default to mainnet
    // If a wallet is connected use the same network as the wallet.
    // If there's no wallet connected figure out which network to use based on `state.networkOverride`
    // (from local storage), and if that's not set just guess based on the current domain.
    if (this.wallet.network !== null) {
      network = this.wallet.network;
    } else if (this.readOnlyNetworkOverride !== null) {
      network = this.readOnlyNetworkOverride;
    } else if (window.location.host === "stage-dashboard2.dappchains.com") {
      network = 4; // default to rinkeby on staging dashboard
    }
    return network;
  }

  /**
   * @return The name of the Ethereum network the wallet is connected to.
   */
  get networkName(): string {
    switch (this.networkId) {
      case 1:
        return "Mainnet";
      case 4:
        return "Rinkeby";
      default:
        return "Unknown";
    }
  }

  @Mutation
  private _clearWalletState() {
    this.wallet = {
      kind: null,
      network: null,
      address: null,
      provider: null,
      wcProvider: null,
      signer: null,
      oldLoomToken: {
        address: null,
        balance: ethers.constants.Zero
      },
      newLoomToken: {
        address: null,
        balance: ethers.constants.Zero
      },
      isRefreshing: false
    };
  }

  @Mutation
  private _clearTokenBalances() {
    this.wallet.oldLoomToken.balance = ethers.constants.Zero;
    this.wallet.newLoomToken.balance = ethers.constants.Zero;
  }

  @Mutation
  private _setInitialized() {
    this.initialized = true;
  }

  @Mutation
  private _setWalletAddress(address: string) {
    this.wallet.address = address;
    console.log(`Wallet address ${address}`);
  }

  @Mutation
  private _setWalletNetwork(network: number | null) {
    this.wallet.network = network;
  }

  @Mutation
  private _setReadOnlyNetworkOverride(network: number | null) {
    this.readOnlyNetworkOverride = network;
  }

  @Mutation
  private _setWalletProvider(provider: providers.Web3Provider) {
    this.wallet.provider = provider;
  }

  @Mutation
  private _setWalletSigner(signer: ethers.Signer) {
    this.wallet.signer = signer;
  }

  @Mutation
  private _setReadOnlyProvider(provider: providers.Provider) {
    this.readOnlyProvider = provider;
  }

  @Mutation
  private _setWCProvider(provider: any) {
    this.wallet.wcProvider = provider;
  }

  @Mutation
  private _setOldLoomTokenBalance(balance: ethers.BigNumber) {
    this.wallet.oldLoomToken.balance = balance;
  }

  @Mutation
  private _setNewLoomTokenBalance(balance: ethers.BigNumber) {
    this.wallet.newLoomToken.balance = balance;
  }

  @Mutation
  private _setLoomTokenContracts(payload: {
    oldContract: ethers.Contract;
    newContract: ethers.Contract;
  }) {
    this.oldLoomContract = payload.oldContract;
    this.newLoomContract = payload.newContract;
  }

  @Mutation
  private _setStakingContract(stakingContract: ethers.Contract) {
    this.stakingContract = stakingContract;
  }

  @Mutation
  private _setWalletKind(wallet: WalletKind | null) {
    this.wallet.kind = wallet;
  }

  @Mutation
  private _setWalletRefreshing(isRefreshing: boolean) {
    this.wallet.isRefreshing = isRefreshing;
  }

  @Mutation
  private _setLatestBlockTime(timestamp: number) {
    this.latestBlockTime = timestamp;
  }

  @Mutation
  private _setLatestBlockTimer(timerId: number) {
    this.latestBlockTimer = timerId;
  }

  @Mutation
  private _setLatestBlockRequesterCount(count: number) {
    this.latestBlockRequesterCount = count;
  }

  @Action
  setLatestBlockTimer(timerId: number) {
    this._setLatestBlockTimer(timerId);
  }

  /**
   * Enable/disable periodic fetching of the latest mined block from Ethereum.
   * This action can be called by multiple components, each requestLatestBlock(true) should be
   * matched by a corresponding requestLatestBlock(false).
   * @param enable If `true` the store will start periodically fetching the latest block, if `false`
   *               it will stop fetching if no one else requested the latest block info.
   */
  @Action
  async requestLatestBlock(enable: boolean) {
    if (enable && this.latestBlockRequesterCount === 0) {
      this._setLatestBlockRequesterCount(this.latestBlockRequesterCount + 1);
      await this.refreshLatestBlock();
      // Recursively calling setTimeout ensures each refresh completes before the next one,
      // which can't be guaranteed when using setInterval.
      const scheduleRefresh = () => {
        const refreshTimer = window.setTimeout(() => {
          this.refreshLatestBlock()
            .then(() => scheduleRefresh())
            .catch(err => {
              console.error(err);
              scheduleRefresh();
            });
        }, 15000);
        this.setLatestBlockTimer(refreshTimer);
      };
      scheduleRefresh();
    } else if (this.latestBlockRequesterCount === 1) {
      this._setLatestBlockRequesterCount(0);
      this._setLatestBlockTimer(0);
      window.clearTimeout(this.latestBlockTimer);
    }
  }

  @Action
  async createReadOnlyProvider() {
    let networkName: string;
    switch (this.networkId) {
      case 1:
        networkName = "mainnet";
        break;

      case 4:
        networkName = "rinkeby";
        break;

      default:
        throw new Error(`Unsupported Ethereum network ${this.networkId}`);
    }

    const provider: providers.Provider = new providers.InfuraProvider(networkName, {
      projectId: process.env.INFURA_PROJECT_ID
    });
    this._setReadOnlyProvider(provider);
  }

  @Action
  async init() {
    if (this.initialized) {
      return;
    }
    this._setInitialized();

    let state: IPersistedState = {
      walletKind: null,
      networkOverride: null
    };
    const data = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (data !== null) {
      state = Object.assign(state, JSON.parse(data));
    }

    if (state.walletKind !== null) {
      await this.createWalletProvider(state.walletKind);
      this._setWalletKind(state.walletKind);
    }

    if (state.networkOverride !== null) {
      this._setReadOnlyNetworkOverride(state.networkOverride);
    }

    await this.createReadOnlyProviderAndContracts();
  }

  @Action
  async createMetaMaskProvider() {
    const ethereum = (window as any).ethereum;

    ethereum.removeAllListeners();
    ethereum.autoRefreshOnNetworkChange = false;
    // TODO: shouldn't really be necessary to do a reload, just calling createMetaMaskProvider would probably do
    ethereum.on("chainChanged", () => window.location.reload());

    let accounts: string[] = [];
    try {
      accounts = await ethereum.request({ method: "eth_requestAccounts" });
    } catch (err) {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.log("Use rejected MetaMask connection request");
      } else {
        console.error(err);
      }
    }

    const provider = new ethers.providers.Web3Provider(ethereum);
    this._setWalletProvider(provider);
    const network = await provider.getNetwork();
    this._setWalletNetwork(network.chainId);

    await this.changeAccounts(accounts);

    try {
      // The following throws on Trust
      // This callback will get invoked when the user selects a different account in MetaMask
      ethereum.on("accountsChanged", (accounts: string[]) => {
        console.log(`accountsChanged ${accounts}`);
        this.changeAccounts(accounts).catch(err => console.error(err));
      });
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  async changeAccounts(accounts: string[]) {
    if (accounts.length === 0) {
      console.error("Wallet is locked or the user has not connected any accounts");
      return;
    }

    this._setWalletAddress(accounts[0]);
    if (this.wallet.provider) {
      this._setWalletSigner(this.wallet.provider.getSigner(accounts[0]));
    }
    // clear the account specific data
    this._clearTokenBalances();

    await this.createReadOnlyProviderAndContracts();

    // don't wait for data fetching to finish since it'll slow down the initial render
    this.refreshLoomBalances().catch(err => console.error(err));
  }

  @Action
  async createReadOnlyProviderAndContracts() {
    let createReadOnlyProvider = true;
    if (this.readOnlyProvider) {
      // read-only provider isn't tied to the currently selected wallet account,
      // so it only needs to be re-created when the network changes
      const network = await this.readOnlyProvider.getNetwork();
      if (network.chainId === this.wallet.network) {
        createReadOnlyProvider = false;
      }
    }
    if (createReadOnlyProvider) {
      await this.createReadOnlyProvider();
      await this.createContracts();
    }
  }

  @Action
  async createWalletConnectProvider() {
    const wcProvider = new WalletConnectProvider({
      bridge: "https://bridge.walletconnect.org",
      infuraId: "44ad2034a545495ca22dda2a4d50feba"
    });
    // NOTE: WC seems to emit chainChanged every time the provider is enabled, while MetaMask seems
    // to only emit this event if the chain changes after the provider is enabled.
    wcProvider.on("chainChanged", (chainId: number) => {
      if (this.wallet.network !== null && this.wallet.network !== chainId) {
        this._setWalletNetwork(null); // actual network will be set after page is reloaded
        window.location.reload();
      }
    });
    // NOTE: WC seems to emit accountsChanged every time the provider is enabled, unlike MetaMask.
    wcProvider.on("accountsChanged", (accounts: string[]) => {
      console.log(`WalletConnect accountsChanged`);
      this.changeAccounts(accounts).catch(err => console.error(err));
    });
    wcProvider.on("disconnect", (code: number, reason: string) => {
      console.log(`WalletConnect session disconnected code ${code}, reason ${reason}`);
      this.selectWallet(null).catch(err => console.error(err));
    });

    // enable session (triggers QR Code modal)
    await wcProvider.enable();
    this._setWCProvider(wcProvider);

    const provider = new providers.Web3Provider(wcProvider);
    this._setWalletProvider(provider);
    const network = await provider.getNetwork();
    this._setWalletNetwork(network.chainId);
  }

  @Action
  async refreshLoomBalances() {
    if (this.wallet.isRefreshing || !this.oldLoomContract || !this.newLoomContract) {
      return;
    }

    try {
      this._setWalletRefreshing(true);
      const oldBalance = await this.oldLoomContract.balanceOf(this.wallet.address);
      this._setOldLoomTokenBalance(oldBalance);
      const newBalance = await this.newLoomContract.balanceOf(this.wallet.address);
      this._setNewLoomTokenBalance(newBalance);
    } finally {
      this._setWalletRefreshing(false);
    }
  }

  @Action
  async createWalletProvider(wallet: WalletKind) {
    switch (wallet) {
      case WalletKind.MetaMask:
        await this.createMetaMaskProvider();
        break;
      case WalletKind.WalletConnect:
        await this.createWalletConnectProvider();
        break;
      case null:
        break;
      default:
        throw new Error(`Invalid wallet type ${wallet}`);
    }
  }

  @Action
  async createContracts() {
    if (!this.readOnlyProvider) {
      return;
    }

    const network = await this.readOnlyProvider.getNetwork();
    const oldAddress = CONTRACT_ADDRESS[network.chainId].oldToken;
    const newAddress = CONTRACT_ADDRESS[network.chainId].newToken;
    if (oldAddress === undefined) {
      console.error(`Old LOOM token contract hasn't been deployed to network ${network.chainId}`);
      return;
    }
    if (newAddress === undefined) {
      console.error(`New LOOM token contract hasn't been deployed to network ${network.chainId}`);
      return;
    }
    const oldContract = new ethers.Contract(oldAddress, erc20abi, this.readOnlyProvider);
    const newContract = new ethers.Contract(newAddress, erc20abi, this.readOnlyProvider);
    this._setLoomTokenContracts({ oldContract, newContract });

    const stakingContractAddress = CONTRACT_ADDRESS[network.chainId].staking;
    if (stakingContractAddress === undefined) {
      //console.error(`Staking contract hasn't been deployed to network ${network.chainId}`);
      return;
    }
    const stakingContract = new ethers.Contract(
      stakingContractAddress,
      stakingContractABI,
      this.readOnlyProvider
    );
    this._setStakingContract(stakingContract);
  }

  @Action
  async selectWallet(wallet: WalletKind | null) {
    if (wallet === null) {
      if (this.wallet.kind === WalletKind.WalletConnect && this.wallet.wcProvider !== null) {
        // need to disconnect the WalletConnect session so that it clears the account info it stores
        // in local storage, otherwise it's impossible to select another WC wallet after connecting
        // the first one
        await this.wallet.wcProvider.disconnect();
      }
      this._clearWalletState();
    } else {
      await this.createWalletProvider(wallet);
      this._setWalletKind(wallet);
    }

    const state: IPersistedState = {
      walletKind: wallet,
      networkOverride: this.readOnlyNetworkOverride
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
  }

  /**
   * Fetch the latest mined block info from Ethereum. This action shouldn't be invoked directly,
   * use requestLatestBlock instead.
   */
  @Action
  async refreshLatestBlock() {
    if (!this.readOnlyProvider) {
      return;
    }

    const block = await this.readOnlyProvider.getBlock("latest");
    this._setLatestBlockTime(block.timestamp);
  }
}

export default getModule(EthereumStore);

import Vue from "vue";
import VueRouter, { RouteConfig, Route, NavigationGuardNext } from "vue-router";
import WalletSelection from "../views/WalletSelection.vue";
import TokenSwap from "../views/TokenSwap.vue";
import FAQ from "../views/FAQ.vue";
import Support from "../views/Support.vue";
import ethereumStore from "../store/ethereum";

Vue.use(VueRouter);

function requireAccount(to: Route, from: Route, next: NavigationGuardNext) {
  ethereumStore
    .init()
    .then(() => {
      if (ethereumStore.wallet.address === null) {
        next(`/login?redirect=${to.path}`);
      } else {
        next();
      }
    })
    .catch(err => {
      // TODO: should probably show an error banner so the user notices something's wrong
      console.error(err);
      next(err);
    });
}

const stakingDashboard = "https://dashboard.dappchains.com";

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "WalletSelection",
    component: WalletSelection
  },
  {
    path: "/token-swap",
    name: "TokenSwap",
    component: TokenSwap,
    beforeEnter: requireAccount
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ
  },
  {
    path: "/feedback",
    name: "Support",
    component: Support
  },
  // redirect routes that only exist on the old dashboard for now
  {
    path: "/validators",
    beforeEnter() {
      window.location.href = `${stakingDashboard}/validators`;
    }
  },
  {
    path: "/account",
    beforeEnter() {
      window.location.href = `${stakingDashboard}/account`;
    }
  },
  {
    path: "/wallet",
    beforeEnter() {
      window.location.href = `${stakingDashboard}/wallet`;
    }
  },
  {
    path: "/staking-guide",
    beforeEnter() {
      window.location.href = `${stakingDashboard}/staking-guide`;
    }
  },
  //{
  //  path: "/about",
  //  name: "About",
  //  // route level code-splitting
  //  // this generates a separate chunk (about.[hash].js) for this route
  //  // which is lazy-loaded when the route is visited.
  //  component: () =>
  //    import(/* webpackChunkName: "about" */ "../views/About.vue")
  //}
  {
    path: "/",
    redirect: "/faq"
  },
  {
    path: "*",
    redirect: "/faq"
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;

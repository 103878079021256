export default [
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "_rewardsRate",
        type: "uint256"
      },
      {
        internalType: "uint64",
        name: "_migrationStartTime",
        type: "uint64"
      }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "stakeholder",
        type: "address"
      }
    ],
    name: "AccountClosed",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "stakeholder",
        type: "address"
      }
    ],
    name: "AccountOpened",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bool",
        name: "importEnabled",
        type: "bool"
      },
      {
        indexed: false,
        internalType: "bool",
        name: "stakingEnabled",
        type: "bool"
      },
      {
        indexed: false,
        internalType: "bool",
        name: "amendEnabled",
        type: "bool"
      },
      {
        indexed: false,
        internalType: "bool",
        name: "withdrawEnabled",
        type: "bool"
      },
      {
        indexed: false,
        internalType: "bool",
        name: "rewardsEnabled",
        type: "bool"
      },
      {
        indexed: false,
        internalType: "bool",
        name: "forceExtendOnAmend",
        type: "bool"
      }
    ],
    name: "FeaturesChanged",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "OwnershipTransferred",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "stakeholder",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "rewards",
        type: "uint256"
      }
    ],
    name: "RewardsClaimed",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "stakeholder",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "period",
        type: "uint8"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "expiresOn",
        type: "uint256"
      }
    ],
    name: "Staked",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "stakeholder",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "period",
        type: "uint8"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "expiredOn",
        type: "uint256"
      }
    ],
    name: "Unstaked",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "stakeholder",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "Withdrawn",
    type: "event"
  },
  {
    inputs: [],
    name: "BONUS_DENOMINATOR",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    name: "BONUS_NUMERATOR",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    name: "LOCKUP_PERIOD",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "REWARDS_RATE_PRECISION",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "TOKEN_DECIMAL_PRECISION",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amountFromWallet",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_amountFromBalance",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "_period",
        type: "uint8"
      },
      {
        internalType: "bool",
        name: "_extend",
        type: "bool"
      }
    ],
    name: "amend",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "annualBaseRewardsRate",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_stakeholders",
        type: "address[]"
      },
      {
        components: [
          {
            internalType: "uint128",
            name: "balance",
            type: "uint128"
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "period",
                type: "uint8"
              },
              {
                internalType: "uint64",
                name: "unlockOn",
                type: "uint64"
              },
              {
                internalType: "uint128",
                name: "amount",
                type: "uint128"
              }
            ],
            internalType: "struct StakingPool.Stake[]",
            name: "stakes",
            type: "tuple[]"
          }
        ],
        internalType: "struct StakingPool.ExportedAccount[]",
        name: "_accounts",
        type: "tuple[]"
      }
    ],
    name: "batchImportAccounts",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "claimRewards",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "getFeatures",
    outputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "importEnabled",
            type: "bool"
          },
          {
            internalType: "bool",
            name: "stakingEnabled",
            type: "bool"
          },
          {
            internalType: "bool",
            name: "amendEnabled",
            type: "bool"
          },
          {
            internalType: "bool",
            name: "withdrawEnabled",
            type: "bool"
          },
          {
            internalType: "bool",
            name: "rewardsEnabled",
            type: "bool"
          },
          {
            internalType: "bool",
            name: "forceExtendOnAmend",
            type: "bool"
          }
        ],
        internalType: "struct StakingPool.Features",
        name: "",
        type: "tuple"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_stakeholder",
        type: "address"
      }
    ],
    name: "getStakeholder",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "lastClaimedAt",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "balance",
            type: "uint256"
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "period",
                type: "uint8"
              },
              {
                internalType: "uint256",
                name: "unlockOn",
                type: "uint256"
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256"
              }
            ],
            internalType: "struct StakingPool.UnpackedStake[]",
            name: "stakes",
            type: "tuple[]"
          }
        ],
        internalType: "struct StakingPool.UnpackedStakeholder",
        name: "holder",
        type: "tuple"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "getStats",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "totalStaked",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "totalRewardsClaimed",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "numStakeholders",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "annualBaseRewardsRate",
            type: "uint256"
          }
        ],
        internalType: "struct StakingPool.Stats",
        name: "stats",
        type: "tuple"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "maxStakesPerAccount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "migrationStartTime",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "numStakeholders",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_stakeholder",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "_asAt",
        type: "uint256"
      }
    ],
    name: "pendingRewards",
    outputs: [
      {
        internalType: "uint256",
        name: "rewardsEarned",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "stakeUnlocked",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "_period",
        type: "uint8"
      },
      {
        internalType: "bool",
        name: "_claim",
        type: "bool"
      }
    ],
    name: "restake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_rate",
        type: "uint256"
      }
    ],
    name: "setBaseRewardsRate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "importEnabled",
            type: "bool"
          },
          {
            internalType: "bool",
            name: "stakingEnabled",
            type: "bool"
          },
          {
            internalType: "bool",
            name: "amendEnabled",
            type: "bool"
          },
          {
            internalType: "bool",
            name: "withdrawEnabled",
            type: "bool"
          },
          {
            internalType: "bool",
            name: "rewardsEnabled",
            type: "bool"
          },
          {
            internalType: "bool",
            name: "forceExtendOnAmend",
            type: "bool"
          }
        ],
        internalType: "struct StakingPool.Features",
        name: "_f",
        type: "tuple"
      }
    ],
    name: "setFeatures",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_maxStakes",
        type: "uint256"
      }
    ],
    name: "setMaxStakesPerAccount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "_period",
        type: "uint8"
      }
    ],
    name: "stake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "stakingToken",
    outputs: [
      {
        internalType: "contract IERC20",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalRewardsClaimed",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalStaked",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256"
      },
      {
        internalType: "bool",
        name: "_claim",
        type: "bool"
      }
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  }
];















































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  props: {
    color: String,
    width: String,
    height: String
  }
})
export default class LoomIcon extends Vue {}

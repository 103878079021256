
























import { Component, Vue, Prop } from "vue-property-decorator";
import ethereumStore from "@/store/ethereum";

@Component
export default class NetworkChip extends Vue {
  @Prop({ default: false })
  readonly compact!: boolean;

  get networkName() {
    return ethereumStore.networkName;
  }

  get latestBlockTime(): number {
    return ethereumStore.latestBlockTime;
  }

  created() {
    ethereumStore.requestLatestBlock(true);
  }

  beforeDestroy() {
    ethereumStore.requestLatestBlock(false);
  }
}
